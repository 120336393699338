export default [
  {
    prop: 'id',
    label: 'Mã',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Tên gói',
    minWidth: 120,
    type: 'text'
  },
  {
    prop: 'price',
    label: 'Giá',
    minWidth: 120,
    type: 'number'
  }
]
